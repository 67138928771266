<template>
  <div>
    <section class="mb-4 mb-lg-5">
      <p class="font-20 color-grey mb-2">Luật và cách chơi</p>
      <div class="row row-2r row-1rmb" v-if="$store.getters.getterIsLoadingProduct">
        <content-placeholders
          :rounded="true"
          class="col-lg-2 col-md-4 col-6 p-lg-2 p-1"
          v-for="(item, index) in 6"
          :key="index"
        >
          <content-placeholders-img class="placeholders-law" />
        </content-placeholders>
      </div>
      <div v-else class="row row-2r row-1rmb">
        <div v-for="(item, index) in categoryLuatChoi" :key="index" class="col-lg-2 col-md-4 col-6 p-lg-2 p-1">
          <router-link
            :to="'/luat-choi/' + item.tag"
            class="boxCategoryLuatChoi d-flex flex-column justify-content-center align-items-center pointer"
          >
            <div class="w-60 mb-2">
              <div
                v-lazy-container="{
                  selector: 'img',
                  error: 'https://d604u3cjqmj8m.cloudfront.net/resources/images/no-photo-banners.png'
                }"
                class="img-wrapper img-76-60"
              >
                <img class="img-small" :data-src="item.image_url" />
              </div>
            </div>
            <p>{{ item.title }}</p>
          </router-link>
        </div>
      </div>
    </section>
    <section class="mb-4 mb-lg-5">
      <p class="font-20 color-grey mb-2">Thông tin hỗ trợ</p>
      <div class="row row-2r" v-if="$store.getters.getterIsLoadingProduct">
        <content-placeholders
          :rounded="true"
          class="col-lg-4 px-lg-2 mb-lg-0 mb-2"
          v-for="(item, index) in 3"
          :key="index"
        >
          <content-placeholders-img class="placeholders-support" />
        </content-placeholders>
      </div>
      <div v-else class="row row-2r">
        <div class="col-lg-4 px-lg-2 mb-lg-0 mb-2">
          <router-link
            to="/ho-tro/ho-tro-tai-khoan"
            class="boxCategoryHoTro d-flex flex-column justify-content-center align-items-center pointer"
          >
            <p>Hỗ trợ tài khoản</p>
          </router-link>
        </div>
        <div class="col-lg-4 px-lg-2 mb-lg-0 mb-2">
          <router-link
            to="/ho-tro/ho-tro-tai-khoan"
            class="boxCategoryHoTro d-flex flex-column justify-content-center align-items-center pointer"
          >
            <p>Nạp tiền / Rút tiền</p>
          </router-link>
        </div>
        <div class="col-lg-4 px-lg-2 mb-lg-0 mb-2">
          <router-link
            to="/ho-tro/choi-co-trach-nhiem"
            class="boxCategoryHoTro d-flex flex-column justify-content-center align-items-center pointer"
          >
            <p>Chơi có trách nhiệm</p>
          </router-link>
        </div>
      </div>
    </section>
    <section class="mb-4 mb-lg-0">
      <p v-if="listQuestionFAQ && listQuestionFAQ.length > 0" class="font-20 color-grey mb-2">Câu hỏi thường gặp</p>
      <content-placeholders v-if="$store.getters.getterIsLoadingProduct">
        <content-placeholders-img class="placeholders-question" />
      </content-placeholders>
      <div v-else>
        <div class="questionFAQ">
          <div v-for="(item, index) in listQuestionFAQ" :key="index">
            <ArticleFAQList
              v-if="item"
              :data="item"
              :index="index"
              :activedFAQ="activedFAQ"
              @handlerUpdateFAQ="handlerUpdateFAQ"
            />
          </div>
        </div>
        <!-- <div v-if="content && content.length > 0" class="py-md-0 py-3">
          <div class="fullDescription" v-html="content"></div>
        </div> -->
      </div>
    </section>
  </div>
</template>
<script>
import ArticleFAQList from '../../components/Article/FAQ/ArticleFAQList';
export default {
  components: { ArticleFAQList },
  data() {
    return {
      activedFAQ: 'faq-0'
      // listQuestionFAQ: [
      //   // {
      //   //   title:
      //   //     'Dịch vụ TÀI LỘC cung cấp những sản phẩm nào? Dịch vụ TÀI LỘC cung cấp những sản phẩm nào? Dịch vụ TÀI LỘC cung cấp những sản phẩm nào? Dịch vụ TÀI LỘC cung cấp những sản phẩm nào?',
      //   //   content:
      //   //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur'
      //   // },
      //   // {
      //   //   title: 'Đăng ký tài khoản trên TÀI LỘC như thế nào?',
      //   //   content:
      //   //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur'
      //   // },
      //   // {
      //   //   title: 'Đặt vé trên TÀI LỘC thì thanh toán thế nào?',
      //   //   content:
      //   //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur'
      //   // }
      // ]
    };
  },
  computed: {
    categoryLuatChoi: {
      get() {
        return this.$store.getters.getterCategoryLuatChoi?.filter((item) => item.tag.includes('xsmn'));
      },
      set(newVal) {
        this.$store.commit('setCategoryLuatChoi', newVal);
      }
    },
    listQuestionFAQ: {
      get() {
        return this.$store.getters.getterListQuestionFAQ;
      },
      set(newVal) {
        this.$store.commit('setListQuestionFAQ', newVal);
      }
    },
    isQuestionFAQ() {
      return this.listQuestionFAQ && this.listQuestionFAQ.length > 0;
    },
    isNotLuatChoi() {
      return this.categoryLuatChoi && this.categoryLuatChoi.length <= 0;
    },
    isOnlyMobile() {
      return this.$store.getters.getterIsOnlyMobile;
    }
  },
  created() {
    this.$store.commit('setIsLoadingProduct', true);
    if (this.isNotLuatChoi) {
      this.getArticleByCategoryLuatChoi();
    } else {
      this.$store.commit('setIsLoadingProduct', false);
    }

    if (!this.isQuestionFAQ) {
      this.getArticleByCategoryCauHoiThuongGap();
    }
  },
  methods: {
    handlerUpdateFAQ(id) {
      this.activedFAQ = id;
    },
    getArticleByCategoryLuatChoi() {
      this.$store.dispatch('getArticleByCategorySlug', 'luat-choi').then((resp) => {
        this.categoryLuatChoi = resp;
      });
    },
    getArticleByCategoryCauHoiThuongGap() {
      this.$store.dispatch('getArticleByCategorySlug', 'cau-hoi-thuong-gap').then((resp) => {
        this.listQuestionFAQ = resp;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.boxCategoryHoTro {
  background-color: #00509d;
  border-radius: 3px;
  transition: 0.3s all;
  height: 130px;
  &:hover {
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
  }
  p {
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 1.25rem;
  }
}
.boxCategoryLuatChoi {
  padding: 1rem 0.5rem;
  padding-top: 1.25rem;
  border-radius: 3px;
  transition: 0.3s all;
  background-color: #ffffff;
  &:hover {
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    p {
      color: #00509d;
    }
  }
  p {
    color: #333333;
    font-weight: 600;
  }
}

@media (max-width: map-get($grid-breakpoints, 'fix-lg')) {
  .boxCategoryHoTro {
    height: 80px;
  }
}
</style>
